const textVariables = require('./textVariables');

const channelConfig = {
  id: 'bruna',
  name: 'Bruna',
  brand: 'bruna',
  locale: 'nl-nl',
  gtm: 'GTM-M2V3WVK',
  clangOrigin: 'e.bruna.nl',
  sitemap: '/waroot/sitemap-bruna_nl.xml',
  imageBaseURL: 'https://api.bruna.nl',
  devProxy: {
    base: 'http://localhost:3000',
    images: 'https://testapi.bruna.nl',
  },
  api: {
    be: 'https://local.bruna.nl',
    local: 'https://testapi.bruna.nl',
    dev: 'https://devapi.bruna.nl',
    devstag: 'https://devstagapi.bruna.nl',
    test: 'https://testapi.bruna.nl',
    teststag: 'https://teststagapi.bruna.nl',
    acc: 'https://accapi.bruna.nl',
    accstag: 'https://accstagapi.bruna.nl',
    prd: 'https://api.bruna.nl',
    www: 'https://api.bruna.nl',
    prdstag: 'https://prdstagapi.bruna.nl',
  },
  intershopId: 'tba-bruna_nl-Site',
  footer: {
    legalLinks: [
      { label: 'Algemene voorwaarden', url: 'https://klantenservice.bruna.nl/topic/uBhzBbmpjEH4EQXdj/article/pLS4Ko297xQPG2QTA' },
      { label: 'Privacy', url: '/privacybeleid' },
      { label: 'Cookies', url: '/cookiebeleid' },
      { label: 'Disclaimer', url: 'https://klantenservice.bruna.nl/topic/uBhzBbmpjEH4EQXdj/article/vkHpQ9y44op8mrLou' },
    ],
    icons: [
      { key: 'ideal', url: 'https://klantenservice.bruna.nl/topic/qvMAdTtvYJcXqfkaE' },
      { key: 'mastercard', url: 'https://klantenservice.bruna.nl/topic/qvMAdTtvYJcXqfkaE' },
      { key: 'visa', url: 'https://klantenservice.bruna.nl/topic/qvMAdTtvYJcXqfkaE' },
      { key: 'vvv', url: 'https://klantenservice.bruna.nl/topic/qvMAdTtvYJcXqfkaE' },
      { key: 'boekenbon', url: 'https://klantenservice.bruna.nl/topic/qvMAdTtvYJcXqfkaE' },
      { key: 'boekcadeau', url: 'https://klantenservice.bruna.nl/topic/qvMAdTtvYJcXqfkaE' },
      { key: 'leescadeau', url: 'https://klantenservice.bruna.nl/topic/qvMAdTtvYJcXqfkaE' },
      { key: 'billink', url: 'https://klantenservice.bruna.nl/topic/qvMAdTtvYJcXqfkaE' },
    ],
    showAward: true,
  },
  header: {
    components: ['notificationBar', 'uspBar', 'masthead', 'navigationBar', 'communicationBar', 'saleBar'],
    hasMemberships: false,
    wishListUrl: '/mijn-account/wishlists',
    checkoutUrl: '/winkelmand',
    accountUrl: '/mijn-account',
    loginUrl: '/inloggen',
    accountMenu: [
      {
        id: 'overzicht',
        label: 'common.home.link', // overzicht
        url: '/mijn-account',
        ignoreInOverview: true,
      }, {
        id: 'gegevens',
        label: 'myenv.mainhub.profile-title', // gegevens
        description: 'myenv.mainhub.profile-desc',
        link: 'myenv.mainhub.profile-linktext',
      }, {
        id: 'bestellingen',
        label: 'myenv.mainhub.orders-title', // bestellingen
        description: 'myenv.mainhub.orders-desc',
        link: 'myenv.mainhub.orders-linktext',
      }, {
        id: 'ebooks',
        label: 'myenv.mainhub.ebooks-title', // ebooks
        description: 'myenv.mainhub.ebooks-desc',
        link: 'myenv.mainhub.ebooks-linktext',
      }, {
        id: 'wishlists',
        label: 'myenv.mainhub.wishlist-title', // verlanglijstje
        description: 'myenv.mainhub.wishlist-desc',
        link: 'myenv.mainhub.wishlist-linktext',
      }, {
        id: 'tegoeden',
        label: 'myenv.mainhub.credit-title', // toegoeden
        description: 'myenv.mainhub.credit-desc',
        link: 'myenv.mainhub.credit-linktext',
      },
    ],
    rating: {
      api: 'https://www.kiyoh.com/v1/review/feed.json?hash=rm6jg8jpcux8k6c&limit=1',
      responseAttribute: 'averageRating',
    },
    usp: {
      regular: [
        {
          icon: 'ico-check',
          text: 'header.usp1.text',
          link: 'header.usp1.link',
        }, {
          icon: 'ico-check',
          text: 'header.usp2.text',
          link: 'header.usp2.link',
        }, {
          icon: 'ico-check',
          text: 'header.usp3.text',
          link: 'header.usp3.link',
        },
      ],
    },
  },
  features: {
    chat: {
      active: true,
      data: {
        deploymentId: '0a4a813b-11ad-457f-a3d3-7c2a343609dc',
        environment: 'prod-euc1',
      },
    },
    shippingCosts: {
      active: true,
    },
    allowGiftcards: {
      active: true,
    },
    stockCheck: {
      active: true,
    },
    clickAndCollect: {
      active: true,
      data: {
        prefixLength: 4,
      },
    },
    emailOptIns: {
      active: true,
      data: {
        types: ['newsLetterOptIn'],
      },
    },
    reviewRating: {
      active: true,
      data: {
        url: 'https://www.kiyoh.com/v1/review/feed.json?hash=rm6jg8jpcux8k6c&limit=1',
        attr: 'averageRating',
        link: 'https://www.kiyoh.com/reviews/1066078/bruna',
      },
    },
    usp: {
      active: true,
      data: {
        regular: [
          {
            icon: 'ico-usp-generic',
            text: 'membership.usp1.standard',
            link: 'membership.usp1.standard.link',
          }, {
            icon: 'ico-usp-generic',
            text: 'membership.usp2.standard',
            link: 'membership.usp2.standard.link',
          }, {
            icon: 'ico-usp-generic',
            text: 'membership.usp3.standard',
            link: 'membership.usp3.standard.link',
          },
        ],
        hasSecondaryLink: true,
      },
    },
    polly: {
      active: true,
      data: {
        id: 'HZcNzD8ZsHNYewFnc',
      },
    },
    storePickup: {
      active: true,
    },
    membership: {
      active: false,
    },
    balanceChecker: {
      active: true,
    },
    qAndA: {
      active: true,
    },
  },
  urls: {
    myAccount: '/mijn-account',
    ebooks: '/ebooks',
    customerService: 'https://klantenservice.bruna.nl/',
  },
  themeColors: {
    defaultCatHeaderBGColor: '#FEE6B8',
  },
  priceNotationLocale: 'en-GB',
  iconMap: {
    'ico-usp-membership': 'ico-arrow-circle-right',
    'ico-usp-generic': 'ico-check',
    'ico-check-modern': 'ico-check',
    'ico-wishlist-header': 'ico-heart-outline',
    'ico-wishlist-product-add': 'ico-heart-outline',
    'ico-wishlist-product-added': 'ico-wishlist-added',
    'ico-user-header': 'ico-user-alt',
    'ico-cart': 'ico-shopping-bag',
    'ico-rating': 'ico-star',
  },
  seo: {
    host: 'https://www.bruna.nl',
    defaultTitle: 'Online boeken en cadeaus bestellen - gratis verzending',
    defaultDescription: 'Bestel de leukste boeken en cadeaus online op bruna.nl. Kies uit een enorm assortiment literatuur, kantoorartikelen, eBooks en meer. Gratis thuisbezorgd',
    defaultImagePath: 'https://www.bruna.nl/images/newsletter/social-bruna.png',
    appendix: '| Bruna',
    descriptionAppendix: '✓ Voor 23:00 besteld, morgen in huis! ✓ Gratis verzending vanaf 20,- ✓ Gratis afhalen in de winkel',
    alternates: [],
    fbAppId: '',
    twitterHandle: '@bruna_nl',
    organization: {
      name: 'Bruna',
      description: 'Bruna is marktleider op het gebied van (kinder-)boeken, tijdschriften, dagbladen en wenskaarten, en een van de grootste aanbieders op het gebied van papier- en schrijfwaren, staatsloten, cadeau- en schoolartikelen.',
      url: 'https://www.bruna.nl',
      areaServed: 'NL',
      telephone: '088 780 0600',
      logo: 'https://www.bruna.nl/client/assets/organization-logo.png',
      sameAs: [
        'https://nl-nl.facebook.com/Brunawinkels/',
        'https://twitter.com/Bruna_nl',
        'https://nl.pinterest.com/brunapuntnl/',
        'https://www.instagram.com/bruna_nederland/',
      ],
      email: 'helpdesk@bruna.nl',
    },
  },
  textVariables,
  includeOptimizeFlickerSnippet: false, // TODO: set to true when Neosem starts working with Optimize in jan 2023
  adyen: {
    test: 'test_SXX6KM252ZBV3GIMDBD5JXWHM4A6QU7G',
    live: 'live_33F7ZDK47BCL7CG36NMSODSZOEDU2ZHQ',
  },
  checkout: {
    paymentMethodGiftcard: 'checkout.payment.giftcard-actioncode-voucher',
  },
  filters: {
    storeStockAvailable: 'storeswithstock_br_nl',
  },
};

module.exports = channelConfig;
