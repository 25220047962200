import React, { useEffect } from 'react';
import { getFeature } from '@utils';

const ChatWidget = () => {
  const { deploymentId, environment, isActive } = getFeature('chat');

  if (!isActive) {
    return null;
  }

  useEffect(() => {
    // Controleer of Genesys al is geïnitialiseerd
    if (!window.Genesys) {
      (function (g, e, n, es, ys) {
        g._genesysJs = e;
        g[e] = g[e] || function () {
          (g[e].q = g[e].q || []).push(arguments);
        };
        g[e].t = 1 * new Date();
        g[e].c = es;
        ys = document.createElement('script');
        ys.async = true;
        ys.src = n;
        ys.charset = 'utf-8';
        document.head.appendChild(ys);
      }(
        window,
        'Genesys',
        'https://apps.mypurecloud.de/genesys-bootstrap/genesys.min.js',
        {
          environment,
          deploymentId,
        }
      ));
    }
  }, []);

  const handleStartChat = () => {
    if (window.Genesys) {
      try {
        // eslint-disable-next-line new-cap
        window.Genesys('command', 'Messenger.open');
      } catch (error) {
        console.error('Failed to open Genesys Messenger:', error);
      }
    } else {
      console.error('Genesys is not initialized.');
    }
  };


  return (
    <div>
      <button id="webMessengerButton" onClick={handleStartChat}/>
    </div>
  );
};

export default ChatWidget;
